import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Fields from "./Fields";
import "./Field.css";

export default function Field({
  index,
  field,
  value,
  onChange,
  ...props
}) {
  const [val, setVal] = useState(value);

  function handleChange(event) {
    setVal(event.target.value);
    onChange(event);
  }

  switch(field.type) {
    case 'select':
      return (
        <Form.Control
          as="select"
          value={val}
          onChange={handleChange}
          {...props}
        >
          { field.options.map((opt, key) =>
            <option key={key} value={opt.value}>{opt.name}</option>
          )}
        </Form.Control>
      )
    case 'number':
      return (
        <Form.Control
          type="number"
          step="0.1"
          value={val}
          onChange={handleChange}
          {...props}
        />
      )
    case 'fields':
      return (
        <Fields index={index} fields={field} values={val} onChange={handleChange} />
      )
    default:
      return null;
  }
}