export default {
    s3: {
        REGION: "us-east-1",
        BUCKET: "focus-app-uploads-dev-s3bucket-1f64lqe58q0y5"
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://yp4z6u6yg2.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_qn5KP6PCo",
        APP_CLIENT_ID: "72jllh3bgnpvukuqlhupc76hl3",
        IDENTITY_POOL_ID: "us-east-1:835236ba-ce8a-49bc-8b69-f52dd92f8bab"
    }
};