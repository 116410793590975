import React from "react";
import { useAppContext } from "../libs/contextLib";
import Data from "./Data";
import Page from "./Page";

export default function Home() {
  const { isAuthenticated } = useAppContext();

  return (
    <>
      {isAuthenticated ? (
        <Data />
      ) : (
        <Page />
      )}
    </>
  );
}