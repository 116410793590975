import React, { useState, useEffect } from "react";
import { Container, Button, Navbar, Nav, Row, Col, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";
import LoadingIcon from "../components/LoadingIcon";
import { onError } from "../libs/errorLib";
import "./Countries.css";

export default function Countries() {
  const [isLoading, setIsLoading] = useState(true);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    try {
      let countryList = await API.get("countries", "/countries");

      countryList.sort((a, b) => {
          return (a.countryCode > b.countryCode ? 1 : (a.countryCode < b.countryCode ? -1 : 0));
      });

      setCountries(countryList);
    }
    catch(e) {
      if (e !== 'No countries') {
        onError(e);
      }
    }
  
    setIsLoading(false);
  }

  return (
      <>
        { isLoading ?
            <Container fluid className="centered">
                <LoadingIcon />
            </Container>
            :
            <>
                <Navbar className="breadcrumb">
                    <Nav>
                        <LinkContainer to="/">
                            <Nav.Item>Home</Nav.Item>
                        </LinkContainer>
                        <LinkContainer to="/countries">
                            <Nav.Item>Countries</Nav.Item>
                        </LinkContainer>
                    </Nav>
                    <Nav className="ml-auto justify-content-end">
                        <LinkContainer to="/countries/new">
                            <Button size="sm" variant="success">Add Country</Button>
                        </LinkContainer>
                    </Nav> 
                </Navbar>
                <Container fluid>
                    <Row>
                        { countries.map((country) =>
                            <Col sm="12" md="4" lg="3" key={country.countryCode}>
                                <Card 
                                    className={country.updatedAt ? 'card-accent-success' : 'card-accent-danger'}
                                >
                                    <Card.Header>{country.name}</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            Updated: { country.updatedAt ? (new Date(country.updatedAt)).toLocaleString() : 'Never' }
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="text-right">
                                        <LinkContainer to={"/countries/" + country.countryCode}>
                                            <Button size="sm" variant="info">Update</Button>
                                        </LinkContainer>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Container>
            </>
        }
      </>
  );
}