import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { API } from "aws-amplify";
import countries from "countries-3166";
import { useFormFields } from "../libs/hooksLib";
import "./NewCountry.css";

export default function NewCountry() {
  const history = useHistory();
  const [fields, handleFieldChange] = useFormFields({
    countryCode: ""
  });
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return fields.countryCode.length === 3;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await addCountry(fields.countryCode);
      history.push("/countries");
    } catch(e) {
      setIsLoading(false);
    }
  }

  function addCountry(countryCode) {
    return API.post("countries", "/countries", {
      body: {
        countryCode: countryCode,
        name: countries.values()[countryCode]
      }
    });
  }

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md="9" lg="6">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="countryCode">
              <Form.Label>Country Code</Form.Label>
              <Form.Control
                as="select"
                value={fields.countryCode}
                onChange={handleFieldChange}
              >
                <option>Select Country</option>
                {countries.toArray().map((value, key) => 
                  <option key={key} value={value.code}>{value.name}</option>
                )}
              </Form.Control>
            </Form.Group>
            <LoaderButton
              block
              type="submit"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              Add
            </LoaderButton>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}