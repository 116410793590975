import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Form, Navbar, Nav, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Spreadsheet from "react-spreadsheet";
import LoaderButton from "../components/LoaderButton";
import LoadingIcon from "../components/LoadingIcon";
import FieldsCard from "../components/FieldsCard";
import { API } from "aws-amplify";
import { onError } from "../libs/errorLib";
import fields from "../data/fields.json";
import "./Country.css";

export default function Country() {
  const { countryCode } = useParams();
  const [country, setCountry] = useState(null);
  const [dateData, setDateData] = useState(null);
  const [dateRowLabels, setDateRowLabels] = useState([]);
  const dateColumnLabels = [
    "Population",
    "Covid Cases",
    "Covid Deaths"
  ];

  useEffect(() => {
    function loadCountry() {
      return API.get("countries", `/countries/${countryCode}`);
    }

    async function onLoad() {
      try {
        let c = await loadCountry();
        if(c.date === undefined) {
          c.date = [];
        }
        if(c.info === undefined) {
          c.info = {};
        }
        for(let field of Object.keys(fields)) {
          if(c.info[field] === undefined) c.info[field] = {};
        }
        // initialize all dates
        for(let entry of c.date) {
          let [year, month, day] = entry.date.split('-');
          entry.dateObject = new Date(year, month - 1, day);
        }
        let today = new Date();
        today.setHours(0,0,0,0);
        let start = new Date(2020,0,1);
        let currentIndex = c.date.length - 1;
        let date = [];
        let labels = [];
        while(today > start) {
          today.setDate(today.getDate() -1);
          if(c.date[currentIndex] && c.date[currentIndex].dateObject.getTime() === today.getTime()) {
            date.push([
              {value: c.date[currentIndex].population ? c.date[currentIndex].population : null},
              {value: c.date[currentIndex].cases ? c.date[currentIndex].cases : null},
              {value: c.date[currentIndex].deaths ? c.date[currentIndex].deaths : null}
            ]);
            currentIndex--;
          } else  {
            date.push([
              {value: null},
              {value: null},
              {value: null}
            ]);
          }
          labels.push(today.toISOString().substring(0,10));
        }
        setDateRowLabels(labels);
        setDateData(date);
        setCountry(c);
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        onError(e);
      }
    }

    onLoad();
  }, [countryCode]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleFieldChange(event) {
    let info = country.info ? country.info : {},
    current = info,
    names = event.target.id.split('|');

    do {
      const name = names.shift();
      if(!current[name]) current[name] = {};
      current = current[name];
    } while(names.length > 1);
    
    current[names.shift()] = event.target.value;

    setCountry({
      ...country,
      info: info
    });
  }

  function validateForm() {
    return true;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    try {
      await updateCountry(country.countryCode);
    } catch(e) {
    }

    setIsSubmitting(false);
  }

  function updateCell(cell) {
    setDateData(cell);
  }

  function updateCountry() {
    country.date = [];
    for(let index in dateData) {
      let [population, cases, deaths] =  dateData[index];
      if(population.value !== null || cases.value !== null || deaths.value !== null) {
        country.date.push({
          date: dateRowLabels[index],
          population: population.value,
          cases: cases.value,
          deaths: deaths.value
        });
      }
    }
    return API.put("countries", "/countries/" + country.countryCode, {
      body: country
    });
  }

  return (
      <>
        { isLoading ?
          <Container fluid className="centered">
              <LoadingIcon />
          </Container>
          :
          <>
            <Navbar className="breadcrumb">
                <Nav>
                    <LinkContainer to="/">
                        <Nav.Item>Home</Nav.Item>
                    </LinkContainer>
                    <LinkContainer to="/countries">
                        <Nav.Item>Countries</Nav.Item>
                    </LinkContainer>
                    <Nav.Item>{country.name}</Nav.Item>
                </Nav>
            </Navbar>
            <Form className="full-form" onSubmit={handleSubmit}>
              <Container fluid>
                <Row>
                    <Col lg="4">
                      {Object.keys(fields).map((field, index) => 
                        <FieldsCard
                          onChange={handleFieldChange}
                          index={field}
                          fields={fields[field]}
                          key={index}
                          values={country.info[field]}
                        />
                      )}
                    </Col>
                    <Col lg="8">
                      <Card>
                        <Card.Header>Date Data</Card.Header>
                        <Card.Body>
                          <Spreadsheet
                            columnLabels={dateColumnLabels}
                            rowLabels={dateRowLabels}
                            data={dateData}
                            onChange={updateCell}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                </Row>
              </Container>
              <Navbar bg="dark" variant="dark" sticky="bottom">
                <Nav className="ml-auto">
                  <Nav.Item>
                    <LoaderButton
                      block
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={!validateForm()}
                    >
                      Update
                    </LoaderButton>
                  </Nav.Item>
                </Nav>
              </Navbar>
            </Form>
          </>
        }
      </>
  );
}