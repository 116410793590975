import React from "react";
import "./Page.css";

export default function Page() {
  return (
    <div className="Home">
      <div className="lander">
        <h1>Insights</h1>
        <p>Insights about the covid-19 crisis</p>
      </div>
    </div>
  );
}