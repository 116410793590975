import React from "react";
import { Form } from "react-bootstrap";
import Field from "./Field";
import "./Fields.css";

export default function Fields({
  index,
  fields,
  values,
  onChange,
  ...props
}) {
  return (
      <>
        { Object.keys(fields.fields).map((input, key) => 
          <Form.Group controlId={index + '|' + input} key={key} {...props}>
            <Form.Label>{fields.fields[input].label}</Form.Label>
            <Field
              index={index + '|' + input}
              field={fields.fields[input]}
              onChange={onChange}
              value={values && values[input] ? values[input] : undefined}
            />
          </Form.Group>
        )}
      </>
  );
}