import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { API } from "aws-amplify";
import Map from "../components/Map";
import LoadingIcon from "../components/LoadingIcon";
import { onError } from "../libs/errorLib";
import fields from "../data/fields.json";
import colors from "../data/colors.json";
import "./Data.css";

export default function Data() {
  const [data, setData] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const countryList = await API.get("countries", "/countries");
      let entries = [];
      let keys = [];
      let labels = [];
      let color =[];

      for(const option of fields.indicator.fields.color.options) {
        color.push(colors[option.color]);
        labels.push(option.name);
      }

      for(const country of countryList) {
        if(country.info) {
          let entry = {
            id: country.countryCode,
            value: country.info.indicator.color,
            status: country.foreignInbound
          };
          // switch(country.foreignInbound) {
          //   case 'closed':
          //     entry.value = 1;
          //     break;
          //   case 'citizen':
          //     entry.value = 2;
          //     break;
          //   case 'restricted':
          //     entry.value = 3;
          //     break;
          //   case 'open':
          //     entry.value = 4;
          //     break;
          //   case 'unknown':
          //   default:
          //     entry.value = 0;
          //     break;
          // }
  
          entries.push(entry);
          keys.push(country.countryCode);
        }
      }

      countryList.sort((a, b) => {
          return (a.countryCode > b.countryCode ? 1 : (a.countryCode < b.countryCode ? -1 : 0));
      });

      // color.reverse();

      setData({
        keys: keys,
        values: entries,
        labels: labels,
        colors: color
      });
    }
    catch(e) {
      if (e !== 'No countries') {
        onError(e);
      }
    }
  
    setIsLoading(false);
  }

  async function clickCountry(country) {
    setSelectedCountry(await loadCountry(country.id));
  }

  function loadCountry(countryCode) {
    return API.get("countries", `/countries/${countryCode}`);
  }

  return (
    <>
      { isLoading ?
        <Container fluid className="centered">
          <LoadingIcon />
        </Container>
        :
        <>
          <Container fluid>
            <Row className="map-row">
              <Col lg={{span: 6, offset: 3}}>
                <Map data={data} onClick={clickCountry} />
              </Col>
              { selectedCountry && 
                <Col lg="3">
                  <Card>
                    <Card.Header>{selectedCountry.name}</Card.Header>
                  </Card>
                </Col>
              }
            </Row>
            { selectedCountry &&
              <Row>
                <Col md="4" lg="6">
                  <Card>
                    <Card.Header>{selectedCountry.name}</Card.Header>
                  </Card>
                </Col>
              </Row>
            }
          </Container>
        </>
      }
    </>
  );
}