import React from "react";
import { Card } from "react-bootstrap";
import Fields from "./Fields";
import "./FieldsCard.css";

export default function FieldsCard({
  index,
  fields,
  values,
  onChange,
  ...props
}) {
  return (
    <Card {...props}>
      <Card.Header>{fields.title}</Card.Header>
      <Card.Body>
        <Fields
          index={index}
          fields={fields}
          values={values}
          onChange={onChange}
        />
      </Card.Body>
    </Card>
  );
}